// api.js

import axios from 'axios';

const backendUrl = process.env.NODE_ENV === 'production'
    ? 'https://aqili-aiapp-backend-g2e0gbh4g9cba2aa.eastus-01.azurewebsites.net/'
    : 'http://localhost:5000';

const backendApi = axios.create({
    baseURL: backendUrl,
    withCredentials: true,
});

// Interceptor to add the token to every request
backendApi.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

// Interceptor to handle token updates
backendApi.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status === 401) {
            console.log('Token expired or invalid, redirecting to login');
            localStorage.removeItem('token');
            window.location.href = '/login';
        }
        return Promise.reject(error);
    }
);

// User Data
backendApi.getUserData = () => backendApi.get('/get_user_data');
backendApi.setToken = (token) => {
    backendApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

// Authentication
backendApi.register = (userData) => backendApi.post('/register', userData);
backendApi.verifyEmail = (data) => backendApi.post('/verify-email', data);
backendApi.login = (credentials) => backendApi.post('/login', credentials);
backendApi.logout = () => backendApi.post('/logout');
backendApi.logoutAllSessions = () => backendApi.post('/logout-all-sessions');
backendApi.deleteAccount = () => backendApi.post('/delete-account');
backendApi.googleAuth = (token) => backendApi.post('/auth/google', { token });
backendApi.microsoftAuth = (token) => backendApi.post('/auth/microsoft', { token });

// Password Reset
backendApi.requestPasswordReset = (email) => backendApi.post('/request-password-reset', { email })
    .then(response => {
        if (response.data && response.data.message) {
            return response.data;
        } else {
            throw new Error('Invalid response from server');
        }
    });

backendApi.resetPassword = (email, resetCode, newPassword) => backendApi.post('/reset-password', { email, resetCode, newPassword })
    .then(response => {
        if (response.data && response.data.message) {
            return response.data;
        } else {
            throw new Error('Invalid response from server');
        }
    });

// Image Generation and Management
backendApi.generateImage = (prompt) => backendApi.post('/generate_image', { prompt })
    .then(response => {
        if (response.data && response.data.image && response.data.filename && response.data.metadata_id) {
            return response.data;
        } else {
            throw new Error('Invalid response from server');
        }
    });

backendApi.deleteImage = (filename) => backendApi.post('/delete_image', { filename })
    .then(response => {
        if (response.data && response.data.message) {
            return response.data;
        } else {
            throw new Error('Invalid response from server');
        }
    });

backendApi.getSavedImages = () => backendApi.get('/get_saved_images')
    .then(response => {
        if (response.data && Array.isArray(response.data.images)) {
            return response.data.images;
        } else {
            throw new Error('Invalid response from server');
        }
    });

// Get Image by Filename
backendApi.getImage = (filename) => backendApi.get(`/get_image/${filename}`)
    .then(response => {
        if (response.data && response.data.url) {
            return response.data;
        } else {
            throw new Error('Invalid response from server');
        }
    });

// Save Edited Image (Updated Function with more logging)
backendApi.saveEditedImage = (formData) =>
    backendApi.post('/save_edited_image', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
    .then(response => {
        console.log('Save edited image response:', response);
        if (response.data && response.data.url) {
            return response.data;
        } else {
            console.error('Invalid response from server:', response);
            throw new Error('Invalid response from server');
        }
    })
    .catch(error => {
        console.error('Error in saveEditedImage:', error);
        if (error.response) {
            console.error('Error response:', error.response.data);
        }
        throw error;
    });

// Image Editing
backendApi.editImage = (filename, editType, editParams) => 
    backendApi.post('/edit_image', { filename, edit_type: editType, edit_params: editParams })
    .then(response => {
        if (response.data && response.data.url && response.data.new_filename) {
            return response.data;
        } else {
            throw new Error('Invalid response from server');
        }
    });

// Download Image
backendApi.downloadImage = (url, filename) => backendApi.get('/download_image', { params: { url, filename }, responseType: 'blob' });

// Image Prompts
backendApi.saveImagePrompt = (prompt) => backendApi.post('/save_image_prompt', { prompt });
backendApi.getImagePromptHistory = () => backendApi.get('/get_image_prompt_history');
backendApi.deleteImagePrompt = (promptId) => backendApi.post('/delete_image_prompt', { prompt_id: promptId });

// Chat Systems
backendApi.chat = (formData) => backendApi.post('/chat', formData, {
    headers: {
        'Content-Type': 'multipart/form-data',
    },
});

backendApi.openaiChat = (formData) => backendApi.post('/openai-chat', formData, {
    headers: {
        'Content-Type': 'multipart/form-data',
    },
});

backendApi.geminiChat = (formData) => backendApi.post('/gemini-chat', formData, {
    headers: {
        'Content-Type': 'multipart/form-data',
    },
});

backendApi.deleteChat = (chatId) => backendApi.post('/delete_chat', { chat_id: chatId });
backendApi.getChatHistory = () => backendApi.get('/get_chat_history');
backendApi.getOpenAIChatHistory = () => backendApi.get('/get_openai_chat_history');
backendApi.deleteOpenAIChat = (chatId) => backendApi.post('/delete_openai_chat', { chat_id: chatId });
backendApi.getGeminiChatHistory = () => backendApi.get('/get_gemini_chat_history');
backendApi.deleteGeminiChat = (chatId) => backendApi.post('/delete_gemini_chat', { chat_id: chatId });

// Feedback and Issue Reporting
backendApi.submitFeedback = (feedback) => backendApi.post('/submit-feedback', { feedback });
backendApi.reportIssue = (issue) => backendApi.post('/report-issue', { issue });

// Google Client ID
backendApi.getGoogleClientId = () => backendApi.get('/api/google-client-id');

// Book Creation and Management
backendApi.getBooks = () => backendApi.get('/api/get-books');
backendApi.createBook = (bookData) => backendApi.post('/api/create-book', bookData);
backendApi.getBook = (bookId) => backendApi.get(`/api/get-book/${bookId}`);
backendApi.editBook = (bookId, content) => backendApi.put(`/api/edit-book/${bookId}`, { content });
backendApi.getSuggestions = (bookId, content, prompt) => backendApi.post(`/api/get-suggestions/${bookId}`, { content, prompt });
backendApi.saveRevision = (bookId, content, note) => backendApi.post(`/api/save-revision/${bookId}`, { content, note });
backendApi.exportBook = (bookId, format, layoutOptions) => 
    backendApi.get(`/api/export-book/${bookId}/${format}`, {
        params: { layoutOptions },
        responseType: 'blob'  // This is important for handling file downloads
    });

// Image Analysis
backendApi.analyzeImage = (imageData, filename) => backendApi.post('/analyze_image', { image: imageData, filename });
backendApi.getAnalysisReport = (reportId) => backendApi.get(`/get_analysis_report/${reportId}`);
backendApi.getImageAnalysisReports = (filename) => backendApi.get('/get_image_analysis_reports', { params: { filename } });

// User Image Upload
backendApi.uploadUserImage = (formData) => backendApi.post('/upload_user_image', formData, {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
}).then(response => {
    if (response.data && response.data.url && response.data.filename && response.data.metadata_id) {
        return response.data;
    } else {
        throw new Error('Invalid response from server');
    }
});

backendApi.uploadImage = (formData) => 
    backendApi.post('/api/upload-image', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
    .then(response => {
        console.log('Upload response:', response);
        if (response.data && response.data.url && response.data.filename) {
            return response.data;
        } else {
            throw new Error('Invalid response from server');
        }
    })
    .catch(error => {
        console.error('Upload error:', error);
        if (error.response) {
            console.error('Error response:', error.response.data);
        }
        throw error;
    });

backendApi.downloadIllustration = (bookId, page) => 
    backendApi.get(`/api/download-illustration/${bookId}/${page}`, { responseType: 'blob' });

backendApi.generateIllustration = (bookId, prompt, pageNumber) => 
    backendApi.post('/api/generate-illustration', { bookId, prompt, pageNumber });

backendApi.applyLayout = (bookId, layoutOptions) => 
    backendApi.post(`/api/apply-layout/${bookId}`, { layoutOptions })
        .then(response => {
            if (response.data && response.data.formatted_pdf_url) {
                return response;
            } else {
                throw new Error('Invalid response from server');
            }
        });

backendApi.previewBook = (bookId, layoutOptions) => 
    backendApi.post(`/api/preview-book/${bookId}`, { layoutOptions });

// MFA
backendApi.enableMFA = () => backendApi.post('/enable-mfa');
backendApi.verifyMFA = (code) => backendApi.post('/verify-mfa', { code });
backendApi.disableMFA = () => backendApi.post('/disable-mfa');

// Current User
backendApi.getCurrentUser = () => backendApi.get('/get_current_user');

// Subscription
backendApi.subscribe = (email) => backendApi.post('/api/subscribe', { email });
backendApi.unsubscribe = (email) => backendApi.post('/api/unsubscribe', { email });

// Contact Form
backendApi.submitContactForm = (formData) => backendApi.post('/api/contact', formData)
    .then(response => {
        if (response.data && response.data.message) {
            return response.data;
        } else {
            throw new Error('Invalid response from server');
        }
    });

export { backendApi };
