import React from 'react';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Help = () => {
  const helpSections = [
    {
      title: 'General',
      content: [
        { q: 'What is this web app for?', a: 'This web app is a comprehensive platform for book creation, editing, and AI-assisted content generation. It allows users to write books, generate illustrations, chat with AI assistants, and manage their literary projects.' },
        { q: 'How do I navigate through the app?', a: 'The app has a main navigation menu that allows you to access different sections such as Book Creation, Chat, Image Generation, and your User Profile. Use this menu to switch between different features of the app.' }
      ]
    },
    {
      title: 'Book Creation and Editing',
      content: [
        { q: 'How do I create a new book?', a: 'To create a new book: 1. Navigate to the Book Creation page. 2. Fill in the book details such as title, author, genre, and outline. 3. Click on the "Generate Book" button to create your book.' },
        { q: 'Can I edit my book after it\'s been generated?', a: 'Yes, you can edit your book: 1. Go to the Book List page. 2. Select the book you want to edit. 3. Use the book editor to make changes to your content. 4. Save your changes using the save button.' },
        { q: 'How do I format my book?', a: 'The app provides various formatting options: - Use the Layout Customizer to adjust the overall layout of your book. - Insert chapters and page breaks using the provided buttons in the editor. - Adjust font size and toggle dark mode for better readability while editing.' },
        { q: 'Can I preview my book?', a: 'Yes, you can preview your book: 1. Select a book from your Book List. 2. The app will render a preview of your book, showing how it looks with the current formatting and layout.' }
      ]
    },
    {
      title: 'Chat and AI Assistance',
      content: [
        { q: 'How do I use the AI chat feature?', a: 'To use the AI chat: 1. Navigate to the Chat section. 2. Type your question or prompt in the input field. 3. Press send or hit enter to get a response from the AI.' },
        { q: 'Can I upload files to the chat for analysis?', a: 'Yes, you can upload files to the chat: 1. Click on the paperclip icon in the chat input area. 2. Select the file(s) you want to upload. 3. The AI will analyze the content of the files and use it to provide more informed responses.' },
        { q: 'What types of files can I upload to the chat?', a: 'The chat supports various file types including: - Text files (.txt) - PDF documents (.pdf) - Images (.png, .jpg, .jpeg, .gif)' }
      ]
    },
    {
      title: 'Image Generation and Analysis',
      content: [
        { q: 'How do I generate images for my book?', a: 'To generate images: 1. Go to the Image Generation section. 2. Enter a prompt describing the image you want. 3. Click on the "Generate Image" button. 4. Wait for the AI to create the image based on your prompt.' },
        { q: 'Can I analyze the generated images?', a: 'Yes, you can analyze generated images: 1. After an image is generated, click on the "Analyze Image" button. 2. The AI will provide an analysis report of the image content.' },
        { q: 'How do I view my generated images?', a: 'To view your generated images: 1. Go to the Generated Images section. 2. You\'ll see a gallery of all the images you\'ve generated. 3. Click on an image to view it in full size or to see its analysis reports.' }
      ]
    },
    {
      title: 'User Account and Settings',
      content: [
        { q: 'How do I change my account settings?', a: 'To change your account settings: 1. Click on your user profile icon. 2. Select "Settings" from the dropdown menu. 3. Here you can update your profile information, change your password, and adjust other preferences.' },
        { q: 'Is there a dark mode?', a: 'Yes, the app supports dark mode: 1. Look for a toggle switch, usually represented by a sun/moon icon. 2. Click this toggle to switch between light and dark modes.' },
        { q: 'How do I report an issue or provide feedback?', a: 'To report an issue or provide feedback: 1. Look for the "Feedback" or "Report Issue" buttons in the app. 2. Click on the appropriate button. 3. Fill out the form with your feedback or details about the issue. 4. Submit the form.' },
        { q: 'How can I get help if I\'m stuck?', a: 'If you need help: 1. Check this FAQ section for answers to common questions. 2. Use the AI chat assistant to ask for help or clarification on specific features. 3. Look for a "Help" or "Support" option in the main menu for additional resources. 4. If you still can\'t find an answer, use the "Report Issue" feature to contact support.' }
      ]
    }
  ];

  return (
    <Box sx={{ maxWidth: 800, margin: 'auto', padding: 3, marginTop: 10 }}>
      <Typography variant="h4" gutterBottom>Help and FAQ</Typography>
      {helpSections.map((section, index) => (
        <Accordion key={index}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">{section.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {section.content.map((item, itemIndex) => (
              <Box key={itemIndex} sx={{ mb: 2 }}>
                <Typography variant="subtitle1" fontWeight="bold">{item.q}</Typography>
                <Typography variant="body1">{item.a}</Typography>
              </Box>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default Help;