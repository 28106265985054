import React, { useState, useEffect, createContext, useCallback, lazy, Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { initializeMsal } from './utils/msalConfig';
import './App.css';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import Footer from './components/Footer';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Box, Typography, CircularProgress, useMediaQuery } from '@mui/material';
import { backendApi } from './utils/api';
import { translations } from './translations';
import Help from './components/Help';

// Lazy load components
const ContentSearch = lazy(() => import('./components/ContentSearch'));
const GeneratedImages = lazy(() => import('./components/GeneratedImages'));
const Settings = lazy(() => import('./components/Settings'));
const Chat = lazy(() => import('./components/Chat'));
const OpenAIChat = lazy(() => import('./components/OpenAI'));
const Gemini = lazy(() => import('./components/Gemini'));
const Account = lazy(() => import('./components/Account'));
const SignUp = lazy(() => import('./components/Signup'));
const Login = lazy(() => import('./components/Login'));
const VerifyEmail = lazy(() => import('./components/VerifyEmail'));
const PasswordReset = lazy(() => import('./components/PasswordReset'));
const Book = lazy(() => import('./components/Book'));
const BookList = lazy(() => import('./components/BookList'));
const BookEdit = lazy(() => import('./components/BookEdit'));
const IllustrationGenerator = lazy(() => import('./components/IllustrationGenerator'));
const TermsOfService = lazy(() => import('./components/TermsOfService'));
const PrivacyPolicy = lazy(() => import('./components/PrivacyPolicy'));
const EditImage = lazy(() => import('./components/EditImage'));

export const LanguageContext = createContext();
export const AuthContext = createContext();

function App() {
    const [showLandingPage, setShowLandingPage] = useState(true);
    const [themeMode, setThemeMode] = useState('dark');
    const [language, setLanguage] = useState('en');
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [googleClientId, setGoogleClientId] = useState('');

    const verifyToken = useCallback(async () => {
        if (token) {
            try {
                const response = await backendApi.get('/verify-token');
                if (response.data.valid) {
                    setIsAuthenticated(true);
                    setUser(response.data.user);
                } else {
                    handleLogout();
                }
            } catch (error) {
                console.error('Token verification failed:', error);
                handleLogout();
            }
        } else {
            setIsAuthenticated(false);
            setUser(null);
        }
        setLoading(false);
    }, [token]);

    useEffect(() => {
        const savedTheme = localStorage.getItem('theme') || 'dark';
        setThemeMode(savedTheme);

        const savedLanguage = localStorage.getItem('language') || navigator.language.split('-')[0];
        setLanguage(savedLanguage in translations ? savedLanguage : 'en');

        const fetchAuthConfigs = async () => {
            try {
                const googleResponse = await backendApi.get('/api/google-client-id');
                setGoogleClientId(googleResponse.data.clientId);

                const microsoftResponse = await backendApi.get('/api/microsoft-config');
                initializeMsal(microsoftResponse.data);
            } catch (error) {
                console.error('Error fetching auth configs:', error);
            }
        };

        verifyToken();
        fetchAuthConfigs();
    }, [verifyToken]);

    const handleThemeChange = (newTheme) => {
        setThemeMode(newTheme);
        localStorage.setItem('theme', newTheme);
    };

    const handleLanguageChange = (newLanguage) => {
        setLanguage(newLanguage);
        localStorage.setItem('language', newLanguage);
    };

    const handleLogin = (newToken, userData) => {
        localStorage.setItem('token', newToken);
        setToken(newToken);
        setIsAuthenticated(true);
        setUser(userData);
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        setToken(null);
        setIsAuthenticated(false);
        setUser(null);
    };

    const createAppTheme = (mode) => createTheme({
        palette: {
            mode: mode,
            background: {
                default: mode === 'dark' ? '#333' : '#f7f7f5',
                paper: mode === 'dark' ? '#424242' : '#ffffff',
            },
            text: {
                primary: mode === 'dark' ? '#ffffff' : '#333333',
                secondary: mode === 'dark' ? '#aaaaaa' : '#666666',
            },
            action: {
                hover: mode === 'dark' ? '#555' : '#e0e0e0',
                selected: mode === 'dark' ? '#777' : '#d3d3d3',
            },
            divider: mode === 'dark' ? '#555' : '#e0e0e0',
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        '&:hover': {
                            backgroundColor: mode === 'dark' ? '#555' : '#f0f0f0',
                        },
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        backgroundColor: mode === 'dark' ? '#424242' : '#ffffff',
                        boxShadow: mode === 'dark' ? "none" : "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    },
                },
            },
            MuiContainer: {
                styleOverrides: {
                    root: {
                        paddingLeft: 8,
                        paddingRight: 8,
                        '@media (min-width:600px)': {
                            paddingLeft: 16,
                            paddingRight: 16,
                        },
                    },
                },
            },
        },
    });

    const theme = createAppTheme(themeMode);

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const t = (key) => {
        return translations[language][key] || key;
    };

    const ProtectedRoute = ({ children }) => {
        if (loading) {
            return (
                <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center', 
                    height: '100vh' 
                }}>
                    <CircularProgress size={isMobile ? 30 : 40} />
                </Box>
            );
        }
        return isAuthenticated ? children : <Navigate to="/login" />;
    };

    useEffect(() => {
        if (window.location.pathname === '/') {
            window.location.href = '/landing.html';
        } else {
            setShowLandingPage(false);
        }
    }, []);

    if (showLandingPage) {
        return null;
    }

    return (
        <GoogleOAuthProvider clientId={googleClientId}>
            <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, user, setUser, handleLogin, handleLogout, token, setToken }}>
                <LanguageContext.Provider value={{ language, setLanguage: handleLanguageChange, t }}>
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <div className="App" style={{ display: 'flex', flexDirection: 'column', height: '100vh', overflow: 'hidden' }}>
                            <Header />
                            <div className="main-layout" style={{ 
                                display: 'flex', 
                                flex: 1, 
                                overflow: 'hidden',
                                flexDirection: isMobile ? 'column' : 'row',
                            }}>
                                {isAuthenticated && (
                                    <Sidebar 
                                        isMobile={isMobile}
                                    />
                                )}
                                <div className="main-content" style={{ 
                                    flex: 1, 
                                    display: 'flex', 
                                    flexDirection: 'column', 
                                    overflowY: 'auto',
                                    width: '100%',
                                }}>
                                    <Suspense fallback={
                                        <Box sx={{ 
                                            display: 'flex', 
                                            justifyContent: 'center', 
                                            alignItems: 'center', 
                                            height: '100vh' 
                                        }}>
                                            <CircularProgress size={isMobile ? 30 : 40} />
                                        </Box>
                                    }>
                                        <Routes>
                                            <Route path="/login" element={<Login />} />
                                            <Route path="/signup" element={<SignUp />} />
                                            <Route path="/verify-email/:token" element={<VerifyEmail />} />
                                            <Route path="/password-reset" element={<PasswordReset />} />                                            
                                            <Route path="/search" element={
                                                <ProtectedRoute>
                                                    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflowY: 'auto', padding: isMobile ? '10px' : '20px' }}>
                                                        <Typography variant={isMobile ? "h6" : "h5"} sx={{ padding: isMobile ? '10px' : '20px', fontWeight: 'bold', color: theme.palette.text.primary }}>
                                                            {t('aqiliImageGenerator')}
                                                        </Typography>
                                                        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden', paddingBottom: isMobile ? '10px' : '20px' }}>
                                                            <ContentSearch />
                                                        </Box>
                                                    </Box>
                                                </ProtectedRoute>
                                            } />
                                            <Route path="/images" element={
                                                <ProtectedRoute>
                                                    <Box sx={{ padding: isMobile ? '10px' : '20px', flexGrow: 1, display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
                                                        <Typography variant={isMobile ? "h6" : "h5"} sx={{ marginBottom: isMobile ? '10px' : '20px', fontWeight: 'bold', color: theme.palette.text.primary }}>
                                                            {t('myGeneratedImages')}
                                                        </Typography>
                                                        <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
                                                            <GeneratedImages />
                                                        </Box>
                                                    </Box>
                                                </ProtectedRoute>
                                            } />
                                            <Route path="/settings" element={
                                                <ProtectedRoute>
                                                    <Settings onThemeChange={handleThemeChange} onLanguageChange={handleLanguageChange} />
                                                </ProtectedRoute>
                                            } />
                                            <Route path="/account" element={
                                                <ProtectedRoute>
                                                    <Account />
                                                </ProtectedRoute>
                                            } />
                                            <Route path="/help" element={
                                                <ProtectedRoute>
                                                    <Help />
                                                </ProtectedRoute>
                                            } />
                                            <Route path="/chat" element={
                                                <ProtectedRoute>
                                                    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflowY: 'auto', padding: isMobile ? '10px' : '20px' }}>
                                                        <Typography variant={isMobile ? "h6" : "h5"} sx={{ padding: isMobile ? '10px' : '20px', fontWeight: 'bold', color: theme.palette.text.primary }}>
                                                            {t('aqiliBaro')}
                                                        </Typography>
                                                        <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
                                                            <Chat />
                                                        </Box>
                                                    </Box>
                                                </ProtectedRoute>
                                            } />
                                            <Route path="/openai" element={
                                                <ProtectedRoute>
                                                    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflowY: 'auto', padding: isMobile ? '10px' : '20px' }}>
                                                        <Typography variant={isMobile ? "h6" : "h5"} sx={{ padding: isMobile ? '10px' : '20px', fontWeight: 'bold', color: theme.palette.text.primary }}>
                                                            {t('aqiliOpenAI')}
                                                        </Typography>
                                                        <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
                                                            <OpenAIChat />
                                                        </Box>
                                                    </Box>
                                                </ProtectedRoute>
                                            } />
                                            <Route path="/gemini" element={
                                                <ProtectedRoute>
                                                    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflowY: 'auto', padding: isMobile ? '10px' : '20px' }}>
                                                        <Typography variant={isMobile ? "h6" : "h5"} sx={{ padding: isMobile ? '10px' : '20px', fontWeight: 'bold', color: theme.palette.text.primary }}>
                                                            {t('aqiliGemini')}
                                                        </Typography>
                                                        <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
                                                            <Gemini />
                                                        </Box>
                                                    </Box>
                                                </ProtectedRoute>
                                            } />
                                            <Route path="/book" element={
                                                <ProtectedRoute>
                                                    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflowY: 'auto', padding: isMobile ? '10px' : '20px' }}>
                                                        <Typography variant={isMobile ? "h6" : "h5"} sx={{ padding: isMobile ? '10px' : '20px', fontWeight: 'bold', color: theme.palette.text.primary }}>
                                                            {t('Create Book')}
                                                        </Typography>
                                                        <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
                                                            <Book />
                                                        </Box>
                                                    </Box>
                                                </ProtectedRoute>
                                            } />
                                            <Route path="/books" element={
                                                <ProtectedRoute>
                                                    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflowY: 'auto', padding: isMobile ? '10px' : '0px' }}>
                                                        <Typography variant={isMobile ? "h6" : "h5"} sx={{ padding: isMobile ? '10px' : '0px', fontWeight: 'bold', color: theme.palette.text.primary }}>
                                                            {t('Your Books')}
                                                        </Typography>
                                                        <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
                                                            <BookList />
                                                        </Box>
                                                    </Box>
                                                </ProtectedRoute>
                                            } />
                                            <Route path="/edit-book/:bookId" element={
                                                <ProtectedRoute>
                                                    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflowY: 'auto', padding: isMobile ? '10px' : '20px' }}>
                                                        <BookEdit />
                                                    </Box>
                                                </ProtectedRoute>
                                            } />
                                            <Route path="/generate-illustrations/:bookId" element={
                                                <ProtectedRoute>
                                                    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflowY: 'auto', padding: isMobile ? '10px' : '20px' }}>
                                                        <IllustrationGenerator />
                                                    </Box>
                                                </ProtectedRoute>
                                            } />
                                            <Route path="/terms" element={<TermsOfService />} />
                                            <Route path="/privacy" element={<PrivacyPolicy />} />
                                            <Route path="/app" element={isAuthenticated ? <Navigate to="/images" /> : <Navigate to="/login" />} />
                                            <Route path="/edit-image/:filename" element={
                                                <ProtectedRoute>
                                                    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflowY: 'auto', padding: isMobile ? '10px' : '20px' }}>
                                                        <EditImage />
                                                    </Box>
                                                </ProtectedRoute>
                                            } />
                                            <Route path="*" element={<Navigate to="/app" replace />} />
                                        </Routes>
                                    </Suspense>
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </ThemeProvider>
                </LanguageContext.Provider>
            </AuthContext.Provider>
        </GoogleOAuthProvider>
    );
}

export default App;