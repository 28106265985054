import React, { useContext, useState, useEffect } from 'react';
import { Box, Typography, Link, useMediaQuery, Slide } from '@mui/material';
import { styled } from '@mui/system';
import { LanguageContext } from '../App';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

const FooterContainer = styled(Box)(({ theme, isMobile }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#f5f5f5',
    color: theme.palette.text.secondary,
    textAlign: 'center',
    padding: isMobile ? '10px 0' : '5px 0',
    position: 'fixed',
    width: '100%',
    bottom: 0,
    zIndex: 1100,
    borderTop: `1px solid ${theme.palette.divider}`,
    boxShadow: theme.palette.mode === 'light' ? '0px -2px 4px rgba(0,0,0,0.05)' : 'none',
    transition: 'background-color 0.3s ease',
}));

const FooterLink = styled(Link)(({ theme }) => ({
    color: theme.palette.primary.main,
    textDecoration: 'none',
    marginLeft: '8px',
    marginRight: '8px',
    '&:hover': {
        textDecoration: 'underline',
    },
}));

function Footer() {
    const { t } = useContext(LanguageContext);
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [isVisible, setIsVisible] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            if (isMobile) {
                const currentScrollY = window.scrollY;
                if (currentScrollY < lastScrollY) {
                    setIsVisible(true);
                } else if (currentScrollY > lastScrollY) {
                    setIsVisible(false);
                }
                setLastScrollY(currentScrollY);
            }
        };

        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => window.removeEventListener('scroll', handleScroll);
    }, [isMobile, lastScrollY]);

    return (
        <Slide direction="up" in={!isMobile || isVisible}>
            <FooterContainer isMobile={isMobile}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
                    <Typography variant={isMobile ? "caption" : "body2"} component="span" sx={{ mr: 1 }}>
                        © 2024 aqili AI. All rights reserved.
                    </Typography>
                    <Typography variant={isMobile ? "caption" : "body2"} component="span">
                        <FooterLink onClick={() => navigate('/terms')}>{t('termsOfService')}</FooterLink>
                    </Typography>
                    <Typography variant={isMobile ? "caption" : "body2"} component="span">
                        <FooterLink onClick={() => navigate('/privacy')}>{t('privacyPolicy')}</FooterLink>
                    </Typography>
                </Box>
            </FooterContainer>
        </Slide>
    );
}

export default Footer;