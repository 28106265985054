import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { LanguageContext, AuthContext } from '../App';
import { Box, Button, IconButton, Popper, Paper, ClickAwayListener, Tooltip, Badge, Typography, Avatar, Menu, MenuItem, Drawer } from '@mui/material';
import { styled } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import { FaCog, FaQuestionCircle, FaComments, FaUser, FaRobot, FaBrain, FaBook, FaPlus, FaList } from 'react-icons/fa'; 
import { FiChevronLeft, FiChevronRight, FiMenu } from 'react-icons/fi';

import LogoutConfirmDialog from './LogoutConfirmDialog.tsx';
import Help from './Help';

const SidebarContainer = styled(Box)(({ theme, collapsed, isMobile }) => ({
    width: isMobile ? '240px' : (collapsed ? '80px' : '250px'),
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    padding: theme.spacing(2),
    transition: 'width 0.3s ease',
    position: isMobile ? 'static' : 'relative',
    boxShadow: theme.palette.mode === 'light' ? '4px 0 20px rgba(0, 0, 0, 0.2)' : '4px 0 10px rgba(0, 0, 0, 0.1)',
    borderRight: theme.palette.mode === 'light' ? `2px solid ${theme.palette.divider}` : 'none',
    zIndex: 1000,
    height: '100%',
    overflowY: 'auto',
}));

const CollapseButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(2),
    fontSize: '24px',
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
}));

const SidebarMenu = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
});

const SidebarButton = styled(Button)(({ theme, active }) => ({
    backgroundColor: active === 'true' ? theme.palette.action.selected : 'transparent',
    color: theme.palette.text.primary,
    padding: theme.spacing(1, 2),
    margin: theme.spacing(1, 0),
    borderRadius: theme.shape.borderRadius,
    justifyContent: 'flex-start',
    textAlign: 'left',
    width: '100%',
    textTransform: 'none',
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
    position: 'relative',
}));

const SubMenuItem = styled(Box)(({ theme }) => ({
    padding: theme.spacing(1),
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
    cursor: 'pointer',
    transition: 'transform 0.3s ease',
    '&:hover': {
        transform: 'scale(1.1)',
    },
    border: `2px solid ${theme.palette.primary.main}`,
}));

const UserInfoContainer = styled(Box)(({ theme, collapsed, isMobile }) => ({
    position: isMobile ? 'static' : 'absolute',
    bottom: theme.spacing(6),
    left: theme.spacing(2),
    right: theme.spacing(2),
    display: 'flex',
    flexDirection: collapsed || isMobile ? 'column' : 'row',
    alignItems: 'center',
    justifyContent: collapsed || isMobile ? 'center' : 'flex-start',
    marginTop: isMobile ? theme.spacing(2) : 0,
}));

const Sidebar = ({ isMobile }) => {
    const [collapsed, setCollapsed] = useState(false);
    const [mobileOpen, setMobileOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [bookAnchorEl, setBookAnchorEl] = useState(null);
    const theme = useTheme();
    const { t } = useContext(LanguageContext);
    const { isAuthenticated, user, handleLogout } = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();

    const [unreadNotifications, setUnreadNotifications] = useState(0);
    const [userMenuAnchorEl, setUserMenuAnchorEl] = useState(null);
    const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);

    useEffect(() => {
        // Simulating fetching unread notifications
        setUnreadNotifications(3);
    }, []);

    const toggleSidebar = () => {
        if (isMobile) {
            setMobileOpen(!mobileOpen);
        } else {
            setCollapsed(!collapsed);
        }
    };

    const handleSubmenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleSubmenuClose = () => {
        setAnchorEl(null);
    };

    const handleBookSubmenuOpen = (event) => {
        setBookAnchorEl(event.currentTarget);
    };

    const handleBookSubmenuClose = () => {
        setBookAnchorEl(null);
    };

    const handleNavigation = (path) => {
        navigate(path);
        handleSubmenuClose();
        handleBookSubmenuClose();
        if (isMobile) {
            setMobileOpen(false);
        }
    };

    const isActive = (path) => location.pathname === path;

    const handleUserMenuOpen = (event) => {
        setUserMenuAnchorEl(event.currentTarget);
    };

    const handleUserMenuClose = () => {
        setUserMenuAnchorEl(null);
    };

    const handleLogoutClick = () => {
        handleUserMenuClose();
        setLogoutDialogOpen(true);
    };

    const handleLogoutConfirm = () => {
        setLogoutDialogOpen(false);
        handleLogout();
        navigate('/login');
    };

    const getUserInitials = () => {
        if (user && user.full_name) {
            return user.full_name.split(' ').map(n => n[0]).join('').toUpperCase();
        }
        return 'U';
    };

    const menuItems = [
        { icon: FaComments, label: 'aqiliBaroGPT', path: '/chat' },
        { icon: FaRobot, label: 'aqiliOpenAIGPT', path: '/openai' },
        { icon: FaBrain, label: 'aqiliGemini', path: '/gemini' },
        { icon: FaBook, label: 'Books', path: '/books', subMenu: true },
        { icon: FaUser, label: 'account', path: '/account', authRequired: true },
        { icon: FaCog, label: 'settings', path: '/settings' },
        { icon: FaQuestionCircle, label: 'help', path: '/help', component: Help },
    ];

    const renderSidebarContent = () => (
        <SidebarContainer collapsed={collapsed ? "true" : undefined} isMobile={isMobile}>
            {!isMobile && (
                <CollapseButton onClick={toggleSidebar}>
                    {collapsed ? <FiChevronRight /> : <FiChevronLeft />}
                </CollapseButton>
            )}
            <SidebarMenu>
                <Box sx={{ position: 'relative', width: '100%' }}>
                    <Tooltip title={t('aqiliImageGenerator')} placement="right" arrow disableHoverListener={!collapsed || isMobile}>
                        <SidebarButton 
                            onClick={handleSubmenuOpen}
                            aria-describedby="submenu-popper"
                            active={(isActive('/search') || isActive('/images')).toString()}
                        >
                            <img
                                src="/aqililogorgb.png"
                                alt="aqili"
                                style={{ width: '20px', height: '20px', marginRight: theme.spacing(1) }}
                            />
                            {(!collapsed || isMobile) && t('aqiliImageGenerator')}
                        </SidebarButton>
                    </Tooltip>
                    <Popper
                        id="submenu-popper"
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        placement="right-start"
                        style={{ zIndex: 1500 }}
                    >
                        <ClickAwayListener onClickAway={handleSubmenuClose}>
                            <Paper sx={{ boxShadow: theme.shadows[3] }}>
                                <Box p={1}>
                                    <SubMenuItem onClick={() => handleNavigation('/search')}>{t('generateImages')}</SubMenuItem>
                                    <SubMenuItem onClick={() => handleNavigation('/images')}>{t('viewAllImages')}</SubMenuItem>
                                </Box>
                            </Paper>
                        </ClickAwayListener>
                    </Popper>
                </Box>

                {menuItems.map((item, index) => (
                    <React.Fragment key={index}>
                        {(!item.authRequired || (item.authRequired && isAuthenticated)) && (
                            <Tooltip title={t(item.label)} placement="right" arrow disableHoverListener={!collapsed || isMobile}>
                                <SidebarButton 
                                    onClick={item.subMenu ? handleBookSubmenuOpen : () => handleNavigation(item.path)}
                                    active={isActive(item.path).toString()}
                                >
                                    <item.icon style={{ marginRight: theme.spacing(1) }} />
                                    {(!collapsed || isMobile) && t(item.label)}
                                    {item.label === 'account' && unreadNotifications > 0 && (
                                        <Badge badgeContent={unreadNotifications} color="error" sx={{ ml: 1 }} />
                                    )}
                                </SidebarButton>
                            </Tooltip>
                        )}
                    </React.Fragment>
                ))}

                <Popper id="book-submenu-popper" open={Boolean(bookAnchorEl)} anchorEl={bookAnchorEl} placement="right-start" style={{ zIndex: 1500 }}>
                    <ClickAwayListener onClickAway={handleBookSubmenuClose}>
                        <Paper sx={{ boxShadow: theme.shadows[3] }}>
                            <Box p={1}>
                                <SubMenuItem onClick={() => handleNavigation('/book')}>
                                    <FaPlus style={{ marginRight: theme.spacing(1) }} />
                                    {t('Create Book')}
                                </SubMenuItem>
                                <SubMenuItem onClick={() => handleNavigation('/books')}>
                                    <FaList style={{ marginRight: theme.spacing(1) }} />
                                    {t('View Books')}
                                </SubMenuItem>
                            </Box>
                        </Paper>
                    </ClickAwayListener>
                </Popper>
            </SidebarMenu>

            <UserInfoContainer collapsed={collapsed} isMobile={isMobile}>
                {isAuthenticated && user && (
                    <Tooltip title={collapsed && !isMobile ? (user.full_name || user.username) : ''} placement="right">
                        <StyledAvatar
                            onClick={handleUserMenuOpen}
                            alt={user.full_name || t('profileImage')}
                            src={user.profile_picture || ''}
                            sx={{ width: collapsed && !isMobile ? 40 : 32, height: collapsed && !isMobile ? 40 : 32 }}
                        >
                            {(!user.profile_picture || user.profile_picture === '') && getUserInitials()}
                        </StyledAvatar>
                    </Tooltip>
                )}
                {(!collapsed || isMobile) && isAuthenticated && user && (
                    <Typography variant="body2" sx={{ ml: 1 }}>
                        {user.full_name || user.username}
                    </Typography>
                )}
            </UserInfoContainer>
        </SidebarContainer>
    );

    return (
        <>
            {isMobile && (
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={toggleSidebar}
                    sx={{ position: 'fixed', top: '70px', left: theme.spacing(1), zIndex: 1100 }}
                >
                    <FiMenu />
                </IconButton>
            )}
            {isMobile ? (
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={toggleSidebar}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
                    }}
                >
                    {renderSidebarContent()}
                </Drawer>
            ) : (
                renderSidebarContent()
            )}

            <Menu
                anchorEl={userMenuAnchorEl}
                open={Boolean(userMenuAnchorEl)}
                onClose={handleUserMenuClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                <MenuItem onClick={() => handleNavigation('/account')}>{t('profile')}</MenuItem>
                <MenuItem onClick={() => handleNavigation('/settings')}>{t('settings')}</MenuItem>
                <MenuItem onClick={handleLogoutClick}>{t('logout')}</MenuItem>
            </Menu>

            <LogoutConfirmDialog
                open={logoutDialogOpen}
                onClose={() => setLogoutDialogOpen(false)}
                onConfirm={handleLogoutConfirm}
                t={t}
            />
        </>
    );
};

export default Sidebar;