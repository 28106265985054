import { PublicClientApplication } from "@azure/msal-browser";
import { backendApi } from './api';

let msalInstance = null;
let msalInitPromise = null;

export const initializeMsal = async () => {
  if (!msalInitPromise) {
    msalInitPromise = new Promise(async (resolve, reject) => {
      try {
        const response = await backendApi.get('/api/microsoft-config');
        const msalConfig = {
          auth: {
            clientId: response.data.clientId,
            authority: "https://login.microsoftonline.com/common",  // Change this line
            redirectUri: response.data.redirectUri,
          },
          cache: {
            cacheLocation: "sessionStorage",
            storeAuthStateInCookie: false,
          },
        };
        msalInstance = new PublicClientApplication(msalConfig);
        await msalInstance.initialize();
        resolve(msalInstance);
      } catch (error) {
        console.error('Failed to initialize MSAL:', error);
        reject(error);
      }
    });
  }
  return msalInitPromise;
};

export const getMsalInstance = () => {
  if (!msalInstance) {
    throw new Error('MSAL instance has not been initialized. Call initializeMsal first.');
  }
  return msalInstance;
};

export const loginRequest = {
  scopes: ["User.Read"]
};