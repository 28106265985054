import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

interface LogoutConfirmDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  t: (key: string) => string; // Translation function
}

const LogoutConfirmDialog: React.FC<LogoutConfirmDialogProps> = ({ open, onClose, onConfirm, t }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="logout-dialog-title"
      aria-describedby="logout-dialog-description"
    >
      <DialogTitle id="logout-dialog-title">{t('logoutConfirmTitle')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="logout-dialog-description">
          {t('logoutConfirmMessage')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t('cancel')}
        </Button>
        <Button onClick={onConfirm} color="primary" autoFocus>
          {t('logout')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LogoutConfirmDialog;